import en from "./locales/en.json";
import zh from "./locales/zh-CN.json";
export default defineI18nConfig(() => ({
  legacy: false,
  locale: "zh",
  fallbackLocale: "zh",
  messages: {
    en,
    zh,
  },
}));
